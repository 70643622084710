.article {
  width: 100%;
  max-width: 1000px;

  margin: 50px 0;
  padding: 50px;
  box-sizing: border-box;

  display: grid;
  justify-items: center;
  gap: 30px;

  border: 1px solid #f0ece8;
  border-radius: 12px;
}

@media screen and (max-width: 1024px) {
  .article {
    margin: 0;
    padding: 50px;
    border: none;
  }
}

@media screen and (max-width: 768px) {
  .article {
    padding: 50px 25px;
  }
}

.article__title {
  margin: 0;
  font-weight: 200;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .article__title {
    font-size: 22px;
    line-height: 25px;
  }
}

.article__image {
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
  align-self: center;
  aspect-ratio: 3/2;
  object-fit: cover;
}

.article__text {
  margin: 0;
  font-weight: 200;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
}

.article__list {
  padding: 0 0 0 20px;
  margin: 0;
  display: grid;
  gap: 10px;
}

.article__item {
  margin: 0;
  font-weight: 200;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
}