.header {
  width: 100%;
  max-width: 1440px;
  height: 90px;
  padding: 30px 50px;
  box-sizing: border-box;
  background-color: #181818;
  box-shadow: 0 2px 0 0 rgb(128, 128, 128);
  position: fixed;
  top: 0;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 20px 40px;
  }
}

@media screen and (max-width: 640px) {
  .header {
    padding: 20px 25px;
  }
}