.aboutme {
  max-width: 1440px;
  width: 100%;

  padding: 0;
  box-sizing: border-box;
  background-color: #181818;
  color: #fff;

  display: grid;
  position: relative;
}

@media screen and (max-width: 1280px) {
  .aboutme {
    padding: 0 50px;
  }
}

@media screen and (max-width: 480px) {
  .aboutme {
    padding: 0 20px;
  }
}

.aboutme__title {
  margin: 50px 0 0;
  font-weight: 200;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.aboutme__article {
  padding: 50px 100px;
  box-sizing: border-box;

  display: grid;
  gap: 50px;
}

@media screen and (max-width: 1280px) {
  .aboutme__article {
    padding: 50px 0 0;
  }
}

@media screen and (max-width: 768px) {
  .aboutme__article {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.aboutme__figure {
  margin: 0;
  align-self: flex-start;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .aboutme__figure {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .aboutme__figure_reverse {
    display: flex;
    flex-direction: column-reverse;
  }
}

.aboutme__figcaption {
  display: grid;
  align-content: flex-start;
  gap: 20px;
}

.aboutme__text {
  margin: 0;
  font-weight: 200;
  font-size: 20px;
  line-height: 26px;
  color: #fff;
}

.aboutme__image {
  width: 100%;
  max-width: 600px;
  justify-self: center;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: center;
  border-radius: 12px;
  background-color: #6e6e6e;
}

@media screen and (max-width: 1024px) {
  .aboutme__image {
    max-width: 500px;
    align-self: center;
  }
}