.message__item {
  font-weight: 200;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  display: grid;
  justify-items: center;
}

.message__link {
  margin-top: 10px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 8px;
  transition: 300ms ease;
}

.message__link:hover {
  background-color: #fff;
  transform: translate3d(0px, -3px, 0.01px);
}

.message__image {
  width: 100%;
  background-color: transparent;
  border-radius: 8px;
  object-fit: contain;
  object-position: center;
}