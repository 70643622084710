.footer__bottom {
  width: 100%;
  border-top: 1px solid #808080;
  padding: 40px 0;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0;
  text-transform: uppercase;
  font-weight: 200;
  font-size: 14px;
  line-height: 18px;
  color: #6e6e6e;
  text-align: center;
}

@media screen and (max-width: 1280px) {
  .footer__bottom {
    padding: 35px 50px 40px;
  }
}

@media screen and (max-width: 1024px) {
  .footer__bottom {
    padding: 35px 30px 40px;
  }
}

@media screen and (max-width: 768px) {
  .footer__bottom {
    padding: 35px 10px 40px;
  }
}