.carousel {
  width: 100%;
  max-width: 1440px;
  padding: 100px 0;
  box-sizing: border-box;
  background-color: #181818;
  color: #fff;

  display: grid;
  justify-content: center;
  gap: 40px;
}

@media screen and (max-width: 1280px) {
  .carousel {
    padding: 75px 0;
  }
}

@media screen and (max-width: 1024px) {
  .carousel {
    padding: 50px 0 0;
  }
}

.carousel__title {
  margin: 0;
  text-transform: uppercase;
  justify-self: center;
  font-weight: 200;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
}

.carousel__article {
  padding: 0 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 20px;
}

@media screen and (max-width: 768px) {
  .carousel__article {
    padding: 0;
    position: relative;
  }
}

.carousel__image {
  width: 100%;
  max-width: 600px;
  aspect-ratio: 3 / 2;
  object-fit: cover;
  object-position: center;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (max-width: 768px) {
  .carousel__image {
    max-width: 100%;
  }
}

.left-arrow,
.right-arrow {
  width: 100%;
  max-width: 40px;
  height: 40px;
  padding: 0;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 35px;
  color: #fff;
  cursor: pointer;

  transition: all 300ms ease;
}

@media screen and (max-width: 768px) {

  .left-arrow,
  .right-arrow {
    color: #000;
    position: absolute;
    z-index: 1;
  }

  .left-arrow {
    left: 10px;
  }

  .right-arrow {
    right: 10px;
  }
}