.blog__list {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: stretch;
  justify-content: center;
  gap: 25px;
}

@media screen and (max-width: 1024px) {
  .blog__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .blog__list {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}