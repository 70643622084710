.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
}

.popup_opened {
  opacity: 1;
  visibility: visible;
}

.popup_image {
  background-color: rgba(0, 0, 0, 0.9);
}

.popup__figure {
  display: flex;
  flex-direction: column;
}

.popup__button-close {
  width: 20px;
  height: 20px;
  background-image: url(../../images/close-icon.svg);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: none;
  margin-bottom: 16px;
  padding: 0;
  align-self: flex-end;
  transition: opacity 0.3s linear;
}

@media screen and (min-width: 768px) {
  .popup__button-close {
    width: 32px;
    height: 32px;
    position: relative;
    right: -32px;
    margin-bottom: 8px;
  }
}

.popup__button-close:hover {
  opacity: 0.5;
  cursor: pointer;
}

.popup__image {
  max-width: 70vw;
  max-height: 70vh;
}