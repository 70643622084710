.footer__list {
  width: 100%;
  max-width: 1440px;
  border-top: 1px solid #808080;

  list-style-type: none;
  margin: 0;
  padding: 40px 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

@media screen and (max-width: 768px) {
  .footer__list {
    flex-direction: column;
    align-items: center;
  }
}

.footer__link {
  min-height: 40px;
  height: 100%;
  text-decoration: none;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 22px;
  color: #808080;

  transition: 300ms ease;
}

.footer__link:hover {
  cursor: pointer;
  color: #fff;
  transform: translate3d(0px, -3px, 0.01px);
}