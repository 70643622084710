.photo {
  width: 100%;
  max-width: 1440px;
  padding: 50px 100px;
  box-sizing: border-box;
}

@media screen and (max-width: 1280px) {
  .photo {
    padding: 50px;
  }
}

@media screen and (max-width: 768px) {
  .photo {
    padding: 50px 0;
  }
}


.photo__title {
  margin: 0;
  text-transform: uppercase;
  font-weight: 200;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
  text-align: center;
}

.photo__list {
  list-style-type: none;
  margin: 0;
  padding: 50px 0 0;
  box-sizing: border-box;

  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .photo__list {
    padding: 30px 0 0;
  }
}