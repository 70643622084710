.popup-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  overflow-y: hidden;
  display: grid;
  align-content: center;
  background-color: #181818;
}

.close-button {
  position: fixed;
  top: 30px;
  right: 40px;
  width: 33px;
  height: 33px;
  padding: 0;
  border: none;
  background-color: transparent;
}

@media screen and (max-width: 640px) {
  .close-button {
    right: 28px;
  }
}

.close-button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.close-button:before,
.close-button:after {
  position: absolute;
  content: ' ';
  bottom: 0;
  top: 0;
  height: 33px;
  width: 3px;
  background-color: #fff;
}

.close-button:before {
  transform: rotate(45deg);
}

.close-button:after {
  transform: rotate(-45deg);
}