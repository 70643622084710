.burger-button {
  display: none;
  transition: opacity 0.2s linear;
}

@media screen and (max-width: 768px) {
  .burger-button {
    display: block;
    width: 33px;
    height: 21px;
    padding: 0;
    border: none;
    background-color: transparent;
    position: relative;
    right: 0;
  }
}

.burger-button::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 33px;
  height: 3px;
  background-color: #fff;
}

.burger-button::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 33px;
  height: 3px;
  background-color: #fff;
}

.burger-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.burger-button__span {
  position: absolute;
  top: 9px;
  left: 0px;
  width: 33px;
  height: 3px;
  background-color: #fff;
}