.questions {
  width: 100%;
  max-width: 1080px;
  padding: 100px 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  position: relative;
}

@media screen and (max-width: 1280px) {
  .questions {
    padding: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .questions {
    padding: 50px 30px;
  }
}

@media screen and (max-width: 768px) {
  .questions {
    padding: 50px 20px;
  }
}

.questions::after {
  position: absolute;
  content: '';
  width: 100%;
  top: 0;
  bottom: 4%;
  border-radius: 12px;
  z-index: -1;
}

@media screen and (max-width: 1024px) {
  .questions::after {
    top: 0;
    bottom: 0;
  }
}

.questions__title {
  margin: 0;
  font-weight: 200;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .questions__title {
    font-size: 22px;
    line-height: 25px;
  }
}

.questions__list {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

@media screen and (max-width: 768px) {
  .questions__list {
    gap: 20px;
  }
}