.notfound {
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 100px;
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .notfound {
    padding: 100px 30px 130px;
  }
}

@media screen and (max-width: 768px) {
  .notfound {
    padding: 50px 30px 75px;
  }
}

@media screen and (max-width: 320px) {
  .notfound {
    padding: 50px 10px 75px;
  }
}

.notfound__title {
  margin: 0 0 18px;
  font-weight: 700;
  font-size: 100px;
  line-height: 1.2em;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .notfound__title {
    font-size: 75px;
  }
}

.notfound__subtitle {
  margin: 0 0 24px;
  font-weight: 400;
  font-size: 42px;
  line-height: 58px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .notfound__subtitle {
    font-size: 30px;
    line-height: 36px;
  }
}

.notfound__text {
  margin: 0 0 32px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #6e6e6e;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .notfound__text {
    font-size: 20px;
    line-height: 22px;
  }
}

.notfound__link {
  display: block;
  text-decoration: none;
  padding: 20px 25px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #808080;

  text-transform: uppercase;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: #fff;
  text-align: center;

  transition: 300ms ease;
}

.notfound__link:hover {
  cursor: pointer;
  background-color: #fff;
  color: #000;
  transform: translate3d(0px, -3px, 0.01px);
}