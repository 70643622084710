.message {
  width: 100%;
  max-width: 1440px;
  padding: 110px 0;
  box-sizing: border-box;

  display: grid;
  justify-content: center;
  gap: 30px;

  border-top: 1px solid #808080;
}

@media screen and (max-width: 1024px) {
  .message {
    padding: 50px;
  }
}

@media screen and (max-width: 768px) {
  .message {
    padding: 50px 30px;
  }
}

.message__title {
  margin: 0;
  font-weight: 200;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .message__title {
    font-size: 22px;
    line-height: 30px;
  }
}

.message__links {
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: flex;
  align-content: center;
  justify-content: center;
  gap: 40px;
}

@media screen and (max-width: 768px) {
  .message__links {
    gap: 35px;
  }
}