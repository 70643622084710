.blog {
  width: 100%;
  max-width: 1440px;
  padding: 100px;
  box-sizing: border-box;

  display: grid;
  justify-content: center;
  gap: 25px;

  color: #000;
}

@media screen and (max-width: 1280px) {
  .blog {
    padding: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .blog {
    padding: 50px 30px;
  }
}

@media screen and (max-width: 768px) {
  .blog {
    padding: 50px 20px;
  }
}

@media screen and (max-width: 320px) {
  .blog {
    padding: 50px 10px;
  }
}