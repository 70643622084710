.photo__image {
  width: 100%;
  max-width: 400px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .photo__image {
    max-width: 500px;
    cursor: auto;
  }
}