.aboutme__table {
  width: 100%;
  border-bottom: 1px solid #808080;
  border-top: 1px solid #808080;
  list-style-type: none;
  margin: 0;
  padding: 50px 100px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-content: space-between;
  gap: 28px;
  flex-wrap: wrap;
}

@media screen and (max-width: 1280px) {
  .aboutme__table {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 1024px) {
  .aboutme__table {
    padding: 50px;
  }
}

@media screen and (max-width: 480px) {
  .aboutme__table {
    grid-template-columns: 1fr;
  }
}

.aboutme__table-cell {
  display: flex;
  flex-direction: column;
}

.aboutme__cell-title {
  margin: 0;
  font-weight: 400;
  font-size: 60px;
  line-height: 80px;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .aboutme__cell-title {
    font-size: 50px;
    line-height: 60px;
  }
}

.aboutme__cell-letter {
  margin: 0;
  font-weight: 200;
  text-align: center;
  color: #fff;
}

.aboutme__cell-text {
  margin: 5px 0 0;
  text-transform: uppercase;
  font-weight: 200;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .aboutme__cell-text {
    font-size: 18px;
    line-height: 20px;
  }
}