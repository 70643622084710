.dignity {
  max-width: 1440px;
  padding: 50px 100px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
}

@media screen and (max-width: 1280px) {
  .dignity {
    padding: 50px 50px 0;
  }
}

@media screen and (max-width: 480px) {
  .dignity {
    padding: 50px 20px 0;
  }
}

.dignity__title {
  margin: 0;
  text-transform: uppercase;
  font-weight: 200;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
}

.dignity__list {
  list-style-type: none;
  margin: 50px 0 0;
  padding: 0;
  justify-self: center;

  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .dignity__list {
    margin: 30px 0 0;
    gap: 20px;
  }
}

.dignity__list-item {
  max-width: 390px;
  padding: 50px 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 20px;
  background: #181818;
  border: 1px solid #f0ece8;
  box-shadow: 0px 2px 12px rgba(43, 43, 43, 0.06);
  border-radius: 12px;
}

@media screen and (max-width: 1024px) {
  .dignity__list-item {
    width: 100%;
    max-width: 500px;
  }
}

.dignity__list-title {
  margin: 0;
  font-weight: 200;
  font-size: 25px;
  line-height: 28px;
  text-align: center;
  color: #fff;
}

.dignity__list-text {
  margin: 0;

  font-weight: 200;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #bebebe;
}