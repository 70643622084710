.questions__item {
  width: 100%;
  max-width: 856px;
  padding: 30px 60px;
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid #f0ece8;
  background-color: #181818;
  box-shadow: 0 2px 12px 0 rgb(43 43 43 / 6%);

  display: flex;
  align-items: flex-start;
  gap: 24px;

  transition: ease 300ms;
}

@media screen and (max-width: 768px) {
  .questions__item {
    padding: 30px 25px;
    gap: 18px;
  }
}

@media screen and (max-width: 640px) {
  .questions__item {
    padding: 20px 20px;
  }
}

.questions__item:hover {
  cursor: pointer;
  transform: translate3d(0px, -3px, 0.01px);
}

.questions__button {
  cursor: pointer;
  width: 100%;
  max-width: 50px;
  padding: 0;
  border-radius: 6px;
  border: 1px solid #f0ece8;
  background-color: transparent;
  box-shadow: 0 2px 12px 0 rgb(43 43 43 / 6%);

  transition: ease 300ms;
}

@media screen and (max-width: 768px) {
  .questions__button {
    min-width: 40px;
    max-width: 40px;
  }
}

.questions__button_active {
  border: none;
  color: #000;
  background-color: #fff;
}

.questions__button-text {
  margin: 0;
  box-sizing: border-box;
  display: block;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 200;
  font-size: 45px;
  line-height: 48px;
  color: #f0ece8;

  transition: ease 300ms;
}

@media screen and (max-width: 768px) {
  .questions__button-text {
    font-size: 35px;
    line-height: 38px;
  }
}

.questions__button-text_active {
  font-family: 'Times New Roman', Times, serif;
  font-weight: 200;
  font-size: 45px;
  line-height: 48px;
  color: #000;

  transform: rotate(45deg);
}

@media screen and (max-width: 768px) {
  .questions__button-text_active {
    font-size: 35px;
    line-height: 37px;
  }
}

.questions__subtitle {
  margin: 10px 0 15px;

  font-weight: 200;
  font-size: 20px;
  line-height: 26px;
  color: #f0ece8;
}

@media screen and (max-width: 768px) {
  .questions__subtitle {
    margin: 6px 0 8px;
  }
}

.questions__subtitle_hover {
  font-weight: 200;
  color: #fff;
}

.questions__text {
  opacity: 0;
  margin: 0;

  font-weight: 200;
  font-size: 18px;
  line-height: 22px;
  color: #fff;

  animation: fadeIn 300ms forwards;
}

@keyframes fadeIn {
  0% {
    transform: translateY(-50%);
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}