.service {
  width: 100%;
  max-width: 1440px;
  padding: 100px 25px;
  box-sizing: border-box;

  display: grid;
  align-items: center;
  justify-items: center;
  gap: 50px;
}

@media screen and (max-width: 1280px) {
  .service {
    padding: 50px;
  }
}

@media screen and (max-width: 768px) {
  .service {
    padding: 50px 20px;
  }
}

.service__title {
  margin: 0 20px;
  font-weight: 200;
  font-size: 25px;
  line-height: 30px;

  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .service__title {
    font-size: 22px;
    line-height: 25px;
  }
}

.service__list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service__item {
  max-width: 390px;
  width: 100%;
}

.service__article {
  height: 100%;
  padding: 40px;
  box-sizing: border-box;

  border: 1px solid #f0ece8;
  border-radius: 12px;

  display: grid;
  align-content: flex-start;
  gap: 30px;
}

@media screen and (max-width: 480px) {
  .service__article {
    padding: 40px 20px;
  }
}

.service__figure {
  margin: 0;
  padding: 20px 20px 0;
  box-sizing: border-box;
  display: grid;
  justify-items: center;
  gap: 15px;
}

.service__figure-title {
  margin: 0;
  font-weight: 200;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  color: #fff;
}

.service__figcaption {
  margin: 0;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #fff;
}

.service__list-title {
  margin: 0;
  font-weight: 200;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #fff;
}

.service__article-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 10px;
}

.service__article-text {
  margin: 0;
  font-weight: 200;
  font-size: 18px;
  line-height: 20px;
  color: #fff;
}

.service__article-note {
  margin: 0;
  font-weight: 200;
  font-size: 18px;
  line-height: 20px;
  color: #fff;
}

.service__note {
  margin: 0;
  font-weight: 200;
  font-size: 18px;
  line-height: 20px;
  color: #fff;
  text-align: center;
}