.logo {
  max-width: fit-content;
  margin: 0;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 25px;
  font-weight: 200;
  color: #808080;

  transition: 300ms ease;
}

@media screen and (max-width: 768px) {
  .logo {
    font-size: 25px;
  }
}

@media screen and (max-width: 480px) {
  .logo {
    max-width: 200px;
  }
}

.logo:hover {
  color: #fff;
}

.logo_active {
  color: #fff;
}