.lead {
  width: 100%;
  max-width: 1440px;
  padding: 50px 100px;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

@media screen and (max-width: 1280px) {
  .lead {
    padding: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .lead {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}

@media screen and (max-width: 480px) {
  .lead {
    padding: 50px 20px;
  }
}

.lead__image {
  justify-self: center;
  width: 100%;
  max-width: 500px;
  border-radius: 20px;
  aspect-ratio: 2 / 3;
  object-fit: cover;
  object-position: center;
}

.lead__article {
  justify-self: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.lead__title {
  min-width: max-content;
  margin: 0;
  font-weight: 200;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  text-transform: uppercase;
}

.lead__text {
  min-width: max-content;
  margin: 0;
  font-weight: 200;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 1024px) {

  .lead__title,
  .lead__text {
    min-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .lead__title {
    font-size: 25px;
  }
}

@media screen and (max-width: 480px) {
  .lead__text {
    font-size: 16px;
  }
}