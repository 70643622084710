.blog__item {
  width: 100%;
  max-width: 400px;

  display: grid;
  justify-items: center;
  gap: 25px;

  box-sizing: border-box;
  border: 1px solid #f0ece8;
  box-shadow: 0 2px 12px 0 rgb(43 43 43 / 6%);
  border-radius: 15px;
  text-decoration: none;
}

.blog__item-image {
  width: 100%;
  border-radius: 14px 14px 0 0;
  aspect-ratio: 4 / 3;
  object-fit: cover;
  object-position: center;
}

.blog__item-text {
  margin: 0;
  padding: 0 25px;
  font-weight: 200;
  font-size: 18px;
  line-height: 20px;
  color: #fff;
  text-align: center;
}

.blog__item-button {
  min-width: 80%;

  margin: 0 0 20px;
  padding: 18px 24px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #f0ece8;

  text-decoration: none;
  font-weight: 200;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #fff;

  transition: 300ms ease;
}

.blog__item-button:hover {
  cursor: pointer;
  background-color: #fff;
  color: #000;
}