.nav {
  display: flex;
  align-items: center;
  gap: 50px;
  position: relative;
  transition: 300ms ease;
}

@media screen and (max-width: 1024px) {
  .nav {
    gap: 30px;
  }
}

@media screen and (max-width: 768px) {
  .nav {
    flex-direction: column;
    gap: 40px;
  }
}

.nav__link {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 200;
  font-size: 20px;
  line-height: 22px;
  color: #808080;

  transition: 300ms ease;
}

@media screen and (max-width: 768px) {
  .nav__link {
    font-size: 22px;
    line-height: 26px;
  }
}

.nav__link:hover {
  color: #fff;
}

.nav__link_active {
  color: #fff;
}